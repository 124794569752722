import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isToday, format } from 'date-fns'

dayjs.extend(utc)

const SECONDS_IN_A_DAY = 86400
const SECONDS_IN_A_YEAR = SECONDS_IN_A_DAY * 365

export const getDifferenceOrFormattedDate = (
  dateToCalc: Dayjs | Date | string | null,
  format: string
) => {
  if (!dateToCalc) {
    return '---'
  }

  const date = dayjs(dateToCalc)
  const daysDiff = date.diff(dayjs(), 'day', true)

  const isYesterday = daysDiff > -1 && daysDiff < 0 && !isToday(date.toDate())

  if (isYesterday) {
    return 'Yesterday'
  }

  const isDateToday = isToday(date.toDate()) || (daysDiff >= 0 && daysDiff < 1)

  if (isDateToday) {
    return 'Today'
  }

  return date.format(format)
}

export const getTimeDifferenceInSeconds = (
  startDateTime: Date | string | null,
  endDateTime: Date | string | null
) => {
  if (!startDateTime || !endDateTime) {
    return 0
  }

  const start = dayjs(startDateTime)
  const end = dayjs(endDateTime)

  return end.diff(start, 'seconds')
}

export const secondsToHoursAndMinutes = (seconds: number) => {
  if (!seconds) {
    return '---'
  }

  const totalMinutes = Math.floor(seconds / 60)

  const hours = Math.floor(totalMinutes / 60)
  const remainingMinutes = Math.floor(totalMinutes % 60)

  let stringfied = ''

  if (hours) {
    stringfied += `${hours} hour${hours > 1 ? 's' : ''} `
  }

  if (remainingMinutes) {
    stringfied += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`
  }

  return stringfied
}

export const getMessageTimeIndicator = (
  messageDate: Date | string | null,
  isConversationListItem = false
) => {
  if (!messageDate) {
    return ''
  }

  const now = dayjs()

  const secondsSinceMessageSent = getTimeDifferenceInSeconds(
    dayjs(messageDate).toDate(),
    now.toDate()
  )
  const totalMinutes = Math.floor(secondsSinceMessageSent / 60)

  if (totalMinutes === 0) {
    return 'now'
  }

  if (totalMinutes <= 10) {
    return `${totalMinutes} min ago`
  }

  let formatString = 'hh:mm a'

  if (secondsSinceMessageSent > SECONDS_IN_A_DAY) {
    if (isConversationListItem) {
      formatString = 'DD MMM'
    } else {
      formatString += ', DD MMM'
    }
  }

  if (secondsSinceMessageSent > SECONDS_IN_A_YEAR) {
    formatString += ', YYYY'
  }

  return dayjs(messageDate).format(formatString)
}

export const formatWithTimeZone = (
  date: Date,
  timeZone: string,
  formatStr: string
) => {
  // Convert the date to the target timezone using toLocaleString
  const localizedDate = new Date(date.toLocaleString('en-US', { timeZone }))
  // Format the localized date using date-fns
  return format(localizedDate, formatStr)
}
