'use client'

import { UserJWTTokenPayload } from '@/lib/auth/payload'
import { ReactNode, createContext, useContext, useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'

export const UserContext = createContext<UserJWTTokenPayload | null>(null)

type UserContextProviderProps = {
  children: ReactNode
  payload: UserJWTTokenPayload | null
}

export const UserContextProvider = ({
  payload,
  children,
}: UserContextProviderProps) => {
  useEffect(() => {
    if (payload && payload.user) {
      const userData = payload.user
      Sentry.setUser({
        id: userData.id.toString(),
        email: userData.email,
      })
    } else {
      // Clear Sentry user context if no user data
      Sentry.setUser(null)
    }
  }, [payload])

  return <UserContext.Provider value={payload}>{children}</UserContext.Provider>
}

export const useUserContext = () => useContext(UserContext)
