import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/(panel)/SidebarCollapseButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarLinksList","default"] */ "/app/app/(panel)/SidebarLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/(panel)/styles.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/(panel)/TopBarHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserContextProvider"] */ "/app/app/(panel)/UserContext.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/atoms/Avatar/Avatar.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/atoms/ImageOrPlaceholderOnError/ImageOrPlaceholderOnError.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/atoms/Link/LinkWithEagerPrefetching.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/atoms/RichText/RichTextProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/atoms/Skeleton/Skeleton.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/atoms/Time/Time.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/atoms/Tooltip/Tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/molecules/LogoutButton/LogoutButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/molecules/OrganizationImage/OrganizationImage.module.css");
;
import(/* webpackMode: "eager" */ "/app/components/atoms/CountDot/CountDot.module.css");
;
import(/* webpackMode: "eager" */ "/app/components/atoms/Typography/Typography.module.css");
;
import(/* webpackMode: "eager" */ "/app/components/atoms/RichText/RichText.module.css");
;
import(/* webpackMode: "eager" */ "/app/components/organisms/chat/ChatMessage/ChatMessage.module.css");
;
import(/* webpackMode: "eager" */ "/app/components/atoms/Breadcrumb/Breadcrumb.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/organisms/chat/ChatMessage/ChatMessageAction.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/organisms/chat/ChatMessage/ChatMessageTextArea.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/organisms/chat/FloatingChat/FloatingChat.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/organisms/chat/FloatingChat/FloatingChatButton/FloatingChatButton.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/organisms/chat/FloatingChat/FloatingChatButton/FloatingChatButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/organisms/chat/FloatingChat/FloatingChatButton/FloatingChatButtonError.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/organisms/chat/FloatingChat/FloatingChatWindow/FloatingChatWindow.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/organisms/chat/FloatingChat/FloatingChatWindow/FloatingChatWindowContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/organisms/chat/FloatingChat/FloatingChatWindow/FloatingChatWindowConversationBackButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/organisms/chat/FloatingChat/FloatingChatWindow/FloatingChatWindowIconButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/organisms/chat/FloatingChat/FloatingChatWindow/FloatingChatWindowListItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/organisms/chat/FloatingChat/FloatingChatWindow/FloatingChatWindowListItemButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-error-boundary/dist/react-error-boundary.esm.js");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorIcon"] */ "/app/node_modules/react-hot-toast/dist/index.mjs");
